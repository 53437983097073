<template>
  <div ref="opt_comm" class="opt_comm" @click.prevent="openToggle">
    <!-- 비활성일 때 disabled 클래스 추가 / 100px 너비 타입은 type_short 클래스 추가 -->
    <strong class="screen_out">{{ optionDesc }}</strong
    ><!-- 내용에 따라 다르게 입력 -->
    <span class="screen_out">선택내용 : </span>
    <a ref="aria" href="javascript:void(0);" class="link_opt" aria-expanded="false"
      ><!-- 오픈시 true, 닫혔을 때 false -->
      {{ optionName }} <span class="ico_account ico_opt" />
    </a>
    <div ref="box_opt" class="box_opt">
      <!-- 2019-09-02 추가 시작 -->
      <span class="screen_out">선택옵션 검색</span>
      <div class="info_search">
        <!--        :value="searchKeyword"-->
        <!--        @input="searchKeyword = $event.target.value"-->
        <input
          id="defaultSearch0"
          ref="searchInput"
          v-model="searchKeyword"
          type="text"
          class="inp_comm inp_search"
          title="검색하기"
          @focus="initSearchKeyword"
          @keyup="searchAccount"
        />
        <span class="ico_account ico_search" />
      </div>
      <!-- // 2019-09-02 추가 끝 -->
      <span class="screen_out">선택옵션 목록</span
      ><!-- 2019-09-02 수정 -->
      <ul class="list_opt" role="listbox">
        <li v-for="(item, index) in searchList" :key="index" @click.prevent="selectOption(item)">
          <a
            ref="listIndex"
            href="javascript:void(0);"
            class="link_option"
            role="option"
            :aria-selected="[item.name === optionName ? 'true' : 'false']"
            @keyup.down="downFocus(1)"
            @keyup.up="upFocus(1)"
          >
            {{ item.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import ApiService from "@/services/ApiService";

export default {
  name: "CommOptSearch",
  mixins: [CommLayerMixin],
  props: {
    classList: Array,
    optionName: String,
    optionDesc: String,
    eventName: String,
    url: String,
    validate: Function,
    validateMsg: {
      type: String,
      default: "",
    },
    allFlag: Boolean,
    method: {
      type: String,
      default: "GET",
    },
    aprvlLineAccntList: Array,
    isInit: Boolean, //true로 설정시 첫번째 데이터를 세팅하다
  },
  data() {
    return {
      option: {},
      selectedOption: {},
      displayOptionName: this.optionName,
      resultList: [],
      optionList: [
        {
          name: "전체",
          code: "",
        },
      ],
      searchKeyword: "",
      searchList: [
        {
          name: "전체",
          code: "",
        },
      ],
      indexFocus: 0,
    };
  },
  watch: {
    optionName(optionName) {
      this.displayOptionName = optionName;
    },
  },
  async created() {},
  mounted() {
    if (!this.aprvlLineAccntList || this.aprvlLineAccntList.length == 0) {
      this.getAprvlLineAccntList();
      return;
    }

    this.setAprvlLineAccntInfo(this.aprvlLineAccntList);
  },
  methods: {
    async getAprvlLineAccntList() {
      const result = await ApiService.shared.getData(this.url);
      if (result.code !== "200") {
      }

      this.setAprvlLineAccntInfo(result.data);
    },
    setAprvlLineAccntInfo(data) {
      const list = [];

      if (this.allFlag) list.push({ name: "전체", code: "" });

      if (data) {
        data.forEach((item) => {
          const obj = {};
          obj.name = item.name;
          obj.code = item.aprvlLineAccntNo;
          list.push(obj);
        });
      }
      this.optionList = list;
      this.searchList = this.optionList;
      if (this.isInit) {
        //this.$emit('select', this.optionList[0]); //초기 샐랙트 제거
      }
    },
    openToggle(event) {
      if (this.$refs.aria.getAttribute("aria-expanded") === "true") {
        if (event.target.className.indexOf("inp_search") > 1) {
          return false;
        }
        this.$refs.aria.setAttribute("aria-expanded", "false");
        this.$refs.opt_comm.classList.remove("opt_open");
      } else {
        let optOpenArr = document.querySelectorAll(".opt_open");
        for (let i = 0, leng = optOpenArr.length; i < leng; i++) {
          optOpenArr[i].classList.remove("opt_open");
          optOpenArr[i].querySelector(".link_opt").setAttribute("aria-expanded", "false");
        }
        let searchOpenArr = document.querySelectorAll(".search_on");
        for (let s = 0, sleng = searchOpenArr.length; s < sleng; s++) {
          searchOpenArr[s].classList.remove("search_on");
        }
        this.$refs.aria.setAttribute("aria-expanded", "true");
        this.$refs.opt_comm.classList.add("opt_open");
        this.initSearchKeyword();
      }
    },
    searchAccount(key) {
      const obj = this;
      let keyCode = key.which;
      if (keyCode === 40) {
        this.indexFocus = 0;
        this.$refs.listIndex[0].focus();
      } else {
        this.searchList = this.optionList.filter(function (st) {
          return st.name.includes(obj.searchKeyword);
        });
      }
    },
    selectOption(option) {
      this.selectedOption = option;

      if (!this.validate) {
        this.changeOption();
        return;
      }

      if (!this.validate(option)) {
        this.$refs.opt_comm.classList.remove("error");
        return;
      }

      let isDisplayConfirm = false;
      let aTagList = this.$refs.box_opt.querySelectorAll("a");
      // 기존에 선택된 옵션과 현재 선택한 옵션이 다른지 여부 파악
      aTagList.forEach((a, index) => {
        if (
          a.innerText === this.displayOptionName &&
          this.displayOptionName !== this.selectedOption.name
        ) {
          isDisplayConfirm = true;
        }
      });
      // TODO 공통화
      if (isDisplayConfirm) {
        const layer = {};
        layer.type = "confirm";
        // layer.content = '계정 변경시 등록한 결재 내역, 결재선이 초기화 됩니다.<br>계정을 변경하시겠습니까?' // 레이어 내용
        layer.content = this.validateMsg;
        this._showLayer(layer, this.changeOption);
      } else {
        this.changeOption();
      }
    },
    downFocus(e) {
      const targetList = this.$refs.listIndex;
      if (e === 0) {
        if (targetList.length > 0) {
          targetList[0].focus();
          this.indexFocus = 0;
        }
      } else {
        this.indexFocus = this.indexFocus + 1;
        if (targetList.length > this.indexFocus) {
          targetList[this.indexFocus].focus();
        } else {
          this.indexFocus = 0;
          targetList[0].focus();
        }
      }
    },
    changeOption() {
      this.option = this.selectedOption;
      this.displayOptionName = this.option.name;

      this.$emit(this.eventName, this.option);
    },
    upFocus(e) {
      const targetList = this.$refs.listIndex;
      if (this.indexFocus === 0) {
        this.indexFocus = targetList.length;
        targetList[targetList.length - 1].focus();
      } else {
        this.indexFocus = this.indexFocus - 1;
        targetList[this.indexFocus].focus();
      }
    },
    initSearchKeyword() {
      this.searchKeyword = "";
      this.searchList = this.optionList;
    },
  },
};
</script>
